import React from 'react'
import Logo from '../../Images/adi-logo.svg'
import Spot1 from '../../Images/analog2-spot.jpg'
import Spot2 from '../../Images/what-we-do-spot.jpg'
import Spot3 from '../../Images/esg-spot.jpg'
import Spot4 from '../../Images/spot1-hp-324.jpg'
import Spot5 from '../../Images/spot2-hp-324.jpg'
import Spot6 from '../../Images/signals-spot.jpg'
import Spot7 from '../../Images/ezone-spot.jpg'
import Spot8 from '../../Images/analog-dialogue-spot.jpg'
import Aero1 from '../../Images/aerospace-hp.jpg'
import Aero2 from '../../Images/consumer-hp.jpg'
import Aero3 from '../../Images/data-center-hp.jpg'
import Aero4 from '../../Images/energy-hp.jpg'
import Aero5 from '../../Images/healthcare-hp.jpg'
import Aero6 from '../../Images/industrial-hp.jpg'
import Aero7 from '../../Images/instrumentation-hp.jpg'
import Aero8 from '../../Images/intelligent-buildings-hp2.jpg'
import Aero9 from '../../Images/wireless-comms-hp.jpg'

function Home() {
  const acelData = [
    {
      id: 1,
      img: Spot1,
      tag: 'Introducing the New Analog.com',
    },
    {
      id: 2,
      img: Spot2,
      tag: 'What We Do (Signals+)',
    },
    {
      id: 3,
      img: Spot3,
      tag: 'Environment, Social, and Governance',
    },
  ]
  const aeroData = [
    {
      id: 1,
      img: Aero1,
      tag: 'Aerospace and Defense Systems',
    },
    {
      id: 2,
      img: Aero2,
      tag: 'Consumer Technology Solutions',
    },
    {
      id: 3,
      img: Aero3,
      tag: 'Data Center Solutions',
    },
    {
      id: 4,
      img: Aero4,
      tag: 'Energy Solutions',
    },
    {
      id: 5,
      img: Aero5,
      tag: 'Healthcare Solutions',
    },
    {
      id: 6,
      img: Aero6,
      tag: 'Industrial Automation',
    },
    {
      id: 7,
      img: Aero7,
      tag: 'Instrumentation and Measurement',
    },
    {
      id: 8,
      img: Aero8,
      tag: 'Intelligent Building Solutions',
    },
    {
      id: 9,
      img: Aero9,
      tag: 'Wireless Communication Solutions',
    },
  ]
  const newAcelData = [
    {
      id: 1,
      img: Spot6,
      tag: 'Signals+',
      info: 'Discover timely thought leadership videos and articles, and relevant customer stories.',
    },
    {
      id: 2,
      img: Spot7,
      tag: 'EngineerZone',
      info: 'Share expertise, connect with peers, and ask design questions on EngineerZone™.',
    },
    {
      id: 3,
      img: Spot8,
      tag: 'Analog Dialogue',
      info: 'Engineering’s definitive, technical resource journal for innovative design.',
    },
  ]
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    })
  }

  return (
    <div className='home'>
      <div className='home-hero'>
        <div className='nav'>
          <img src={Logo} alt='' />
        </div>
        <div className='hero-body'>
          <h1>Collaborating for Automotive Breakthroughs</h1>
          <p>
            ADI’s wireless battery management system solutions and domain
            expertise are helping global automakers like Lotus build flexible
            and scalable EV platforms.
          </p>
          <button onClick={scrollToBottom}>Join Us</button>
        </div>
      </div>
      <div className='acel'>
        <h1>Accelerating Human Breakthroughs</h1>
        <p>
          Together, with our customers, we cocreate solutions across aerospace,
          automotive, communications, consumer, energy, industrial automation,
          and healthcare.
        </p>
        <div className='cards'>
          {acelData.map((item) => (
            <div className='card' key={item.id}>
              <div className='one'>
                <img src={item.img} alt='' />
              </div>
              <div className='two'>
                <h2>{item.tag}</h2>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='trust'>
        <div className='one'>
          <h1>Trusted Automotive Solutions</h1>
          <p>
            ADI’s Automotive solutions are featured across emerging automotive
            megatrends, from feature-rich cabins to full vehicle electrification
            and efficiency improvements.
          </p>
          <button onClick={scrollToBottom}>Join Us</button>
        </div>
        <div className='two'>
          <img src={Spot4} alt='' />
        </div>
      </div>
      <div className='aero'>
        {aeroData.map((item) => (
          <div className='card' key={item.id}>
            <img src={item.img} alt='' />
            <h2>{item.tag}</h2>
          </div>
        ))}
      </div>
      <div className='trust'>
        <div className='one'>
          <h1>Wired and Wireless Automotive BMS</h1>
          <p>
            ADI’s precision automotive BMS and wBMS provide monitoring and
            management to support better EV battery health. Optimizing battery
            management can improve vehicle range, battery energy density, charge
            capacity, and overall performance.
          </p>
          <button onClick={scrollToBottom}>Join Us</button>
        </div>
        <div className='two'>
          <img src={Spot5} alt='' />
        </div>
      </div>
      <div className='acel'>
        <h1>Featured Resources</h1>
        <p>
          Read informative thought leadership and customer stories on Signals+;
          share your expertise, connect with peers, and ask your design
          questions on EngineerZone™; exchange information on circuits, systems,
          and software for real-world signal processing on Analog Dialogue.
        </p>
        <div className='cards'>
          {newAcelData.map((item) => (
            <div className='card2' key={item.id}>
              <div className='one'>
                <img src={item.img} alt='' />
              </div>
              <div className='two'>
                <h2>{item.tag}</h2>
                <p>{item.info}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Home
