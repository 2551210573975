import React, { useState } from 'react'
import './Landing.scss'
import Logo from '../../Images/adi-logo.svg'
import { FaLongArrowAltDown } from 'react-icons/fa'
import { useToasts } from 'react-toast-notifications'
import Home from './Home'

function Landing() {
  const totalBoards = 13
  const [boardCount, setBoardCount] = useState(1)
  const progressPercent = (boardCount / totalBoards) * 100
  const { addToast } = useToasts()
  ///////
  const statesArray = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ]
  const [referenceNumber, setReferenceNumber] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [states, setStates] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [organize, setOrganize] = useState('')
  const [employer, setEmployer] = useState('')
  const [remote, setRemote] = useState('')
  const [restriction, setRestriction] = useState('')
  const [employment, setEmployment] = useState('')
  const [hire, setHire] = useState('')
  const [tough, setTough] = useState('')
  const [understand, setUnderstand] = useState('')
  const [CV, setCV] = useState('')
  const [payment, setPayment] = useState('')
  const [loading, setLoading] = useState(false)

  const step1 = (e) => {
    e.preventDefault()
    setBoardCount(2)
  }
  const step2 = (e) => {
    e.preventDefault()
    setBoardCount(3)
  }
  const step3 = (e) => {
    e.preventDefault()
    setBoardCount(4)
  }
  const step4 = (e) => {
    e.preventDefault()
    setBoardCount(5)
  }
  const step5 = (e) => {
    e.preventDefault()
    setBoardCount(6)
  }
  const step6 = (e) => {
    e.preventDefault()
    setBoardCount(7)
  }
  const step7 = (e) => {
    e.preventDefault()
    setBoardCount(8)
  }
  const step8 = (e) => {
    e.preventDefault()
    setBoardCount(9)
  }
  const step9 = (e) => {
    e.preventDefault()
    setBoardCount(10)
  }
  const step10 = (e) => {
    e.preventDefault()
    setBoardCount(11)
  }
  const step11 = (e) => {
    e.preventDefault()
    setBoardCount(12)
  }
  const step12 = (e) => {
    e.preventDefault()
    setBoardCount(13)
  }
  const encode = (data) => {
    const formData = new FormData()
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k])
    })
    return formData
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const data = {
      referenceNumber,
      firstName,
      lastName,
      email,
      phone,
      address,
      city,
      states,
      zipCode,
      organize,
      employer,
      remote,
      restriction,
      employment,
      hire,
      tough,
      understand,
      CV,
      payment,
    }
    console.log(data)
    fetch('https://fcd-be-e3a65f61233d.herokuapp.com/job/apply', {
      method: 'POST',
      headers: { 'content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          addToast(
            'Application submitted Successfully. We will get in touch with you shortly.',
            {
              appearance: 'success',
              autoDismiss: true,
            }
          )
          setTimeout(() => {
            window.scrollTo(0, 0)
            window.location.reload()
          }, 4000)
        }
      })
      .catch((error) =>
        addToast('Application submission failed. Try again', {
          appearance: 'error',
          autoDismiss: true,
        })
      )
  }
  return (
    <div className='landing'>
      <Home />
      {/* <div className='hero'>
        <div className='row'>
          <div className='left'>
            <img src={Logo} alt='' />
          </div>
          <div className='right'>
            <a href='#details'>
              <FaLongArrowAltDown />
            </a>
          </div>
        </div>
      </div> */}
      <div className='details' id='details'>
        <div className='cover'>
          <div className='left'>
            <h2>JOIN OUR WORKFORCE NOW</h2>
          </div>
          <div className='right'>
            {/* <div className='text'>
              <p>
                If you thrive on challenge and collaboration, Analog Devices
                offers opportunities on a global scale. We’re looking for more
                people to join our team—people who are motivated, energetic,
                eager to learn and grow. We make it a priority to support the
                aspirations of every individual, with international
                opportunities, ongoing training, development, and first-class
                benefits.
              </p>
              <h3>
                Analog Devices Group located in Lake Mary, Florida presently
                requires a:
              </h3>
              <h3>Customer Service Representative</h3>
              <h3>Position Description:</h3>
              <p>
                Customer service representatives help customers with complaints
                and questions, give customers information about products and
                services, take orders, and process returns.
              </p>
              <h3>Responsibilities:</h3>
              <ul>
                <li>Receiving and placing customer service telephone calls </li>
                <li>
                  Maintaining solid customer relationships by handling questions
                  and concerns with speed and professionalism
                </li>
                <li>
                  Resolving customer complaints, managing database records,
                  drafting status reports n customer service issues
                </li>
                <li>Assist with the updates of policies and procedures.</li>
                <li>
                  Maintain logs of activities and completed work Perform other
                  duties as required.
                </li>
              </ul>
              <h3>Qualifications</h3>
              <ul>
                <li>
                  Post-secondary degree or diploma in Customer Service
                  Representation is an asset.
                </li>
                <li>Well-developed computer skills in Microsoft Office.</li>
                <li>Excellent organization and communication skills.</li>
                <li>
                  Ability to work accurately while being attentive to detail.
                </li>
                <li>
                  Ability to work independently as well as a member of a team.
                </li>
                <li>
                  Ability to maintain confidentiality when dealing with
                  sensitive information.
                </li>
              </ul>
              <h3>What we can Offer You </h3>
              <ul>
                <li>
                  A comprehensive compensation package including competitive
                  bonuses
                </li>
                <li>
                  A progressive career path including global opportunities
                </li>
                <li>
                  Work in a dynamic, collaborative, progressive, and
                  high-performing team
                </li>
                <li>
                  Access to a global network of highly qualified professionals
                  for exchanging knowledge and ideas, building skills, and
                  sharing experiences{' '}
                </li>
                <li>
                  Barrick is committed to creating a diverse environment and is
                  proud to be an equal opportunity employer
                </li>
              </ul>
              <p>
                If you received an email regarding this position from our
                Recruitment And Talent acquisition Agent via email, kindly use
                your reference number to begin your Application and online
                interview process, afterwards you will receive a phone call
                within 24 hours after your application has been reviewed.{' '}
              </p>
              <p>
                We are an equal opportunity employer and all qualified
                applicants will receive consideration for employment without
                regard to race, color, religion, sex, sexual orientation, gender
                identity, national origin, disability status, protected veteran
                status, or any other characteristic protected by law.
              </p>
            </div> */}
            <div className='form'>
              <div className='steps'>
                <h2>Step {boardCount} of 13</h2>
              </div>
              <section className='progress'>
                <section
                  className='progress-value'
                  style={{ width: `${progressPercent}%` }}
                ></section>
              </section>
              <div className='main-form'>
                {boardCount === 1 && (
                  <form className='cardX' onSubmit={step1}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          Application Reference Number <span>*</span>
                        </p>
                        <input
                          type='text'
                          required
                          name='referenceNumber'
                          value={referenceNumber}
                          onChange={(e) => setReferenceNumber(e.target.value)}
                        />
                      </div>
                      <div className='card'>
                        <p>
                          Firstname <span>*</span>
                        </p>
                        <input
                          type='text'
                          required
                          name='firstname'
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className='card'>
                        <p>
                          Lastname <span>*</span>
                        </p>
                        <input
                          type='text'
                          required
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 2 && (
                  <form className='cardX' onSubmit={step2}>
                    <div className='row'>
                      <div className='card'>
                        <p>
                          Email <span>*</span>
                        </p>
                        <input
                          type='email'
                          required
                          name='email'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className='card'>
                        <p>
                          Phone <span>*</span>
                        </p>
                        <input
                          type='tel'
                          required
                          name='phone'
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(1)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 3 && (
                  <form className='cardX' onSubmit={step3}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          Address <span>*</span>
                        </p>
                        <input
                          type='text'
                          required
                          name='address'
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div className='card'>
                        <p>
                          City <span>*</span>
                        </p>
                        <input
                          type='text'
                          required
                          name='city'
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                      <div className='card'>
                        <p>
                          State <span>*</span>
                        </p>
                        <select
                          name='states'
                          id=''
                          value={states}
                          onChange={(e) => setStates(e.target.value)}
                        >
                          <option value=''>-select-</option>
                          {statesArray.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </div>
                      <div className='card '>
                        <p>
                          Zip Code <span>*</span>
                        </p>
                        <input
                          type='text'
                          required
                          name='zipCode'
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                        />
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(2)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 4 && (
                  <form className='cardX' onSubmit={step4}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          Tell us how you organize, plan, and prioritize your
                          work? <span>*</span>
                        </p>
                        <textarea
                          name=''
                          id=''
                          cols='30'
                          rows='10'
                          value={organize}
                          onChange={(e) => setOrganize(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(3)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 5 && (
                  <form className='cardX' onSubmit={step5}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          Who is your Current or most recent employer?{' '}
                          <span>*</span>
                        </p>
                        <input
                          type='text'
                          required
                          name='employer'
                          value={employer}
                          onChange={(e) => setEmployer(e.target.value)}
                        />
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(4)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 6 && (
                  <form className='cardX' onSubmit={step6}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          Can you work remotely? <span>*</span>
                        </p>
                        <select
                          id=''
                          name='remote'
                          value={remote}
                          onChange={(e) => setRemote(e.target.value)}
                        >
                          <option value=''>-select-</option>
                          <option value='Yes'>Yes</option>
                          <option value='No'>No</option>
                        </select>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(5)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 7 && (
                  <form className='cardX' onSubmit={step7}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          Are there any restrictions to your work availability?
                          If yes, explain what the limitations are{' '}
                          <span>*</span>
                        </p>
                        <textarea
                          name=''
                          id=''
                          cols='30'
                          rows='10'
                          value={restriction}
                          onChange={(e) => setRestriction(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(6)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 8 && (
                  <form className='cardX' onSubmit={step8}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          What Type of employment are you seeking?{' '}
                          <span>*</span>
                        </p>
                        <select
                          id=''
                          name='remote'
                          value={employment}
                          onChange={(e) => setEmployment(e.target.value)}
                        >
                          <option value=''>-select-</option>
                          <option value='Full Time'>Full Time</option>
                          <option value='Part Time'>Part Time</option>
                        </select>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(7)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 9 && (
                  <form className='cardX' onSubmit={step9}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          Why should we hire you? <span>*</span>
                        </p>
                        <textarea
                          name=''
                          id=''
                          cols='30'
                          rows='10'
                          value={hire}
                          onChange={(e) => setHire(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(8)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 10 && (
                  <form className='cardX' onSubmit={step10}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          What is the toughest part of a job for you?{' '}
                          <span>*</span>
                        </p>
                        <textarea
                          name=''
                          id=''
                          cols='30'
                          rows='10'
                          value={tough}
                          onChange={(e) => setTough(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(9)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 11 && (
                  <form className='cardX' onSubmit={step11}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          What do you understand by privacy and code of conduct?{' '}
                          <span>*</span>
                        </p>
                        <textarea
                          name=''
                          id=''
                          cols='30'
                          rows='10'
                          value={understand}
                          onChange={(e) => setUnderstand(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(10)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 12 && (
                  <form className='cardX' onSubmit={step12}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          A link to your CV/Resume <span>*</span>
                        </p>
                        <input
                          type='text'
                          required
                          name='employer'
                          value={CV}
                          onChange={(e) => setCV(e.target.value)}
                        />
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        setBoardCount(11)
                      }}
                    >
                      Previous
                    </button>
                    <button>Next</button>
                  </form>
                )}
                {boardCount === 13 && (
                  <form className='cardX' onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='card dif'>
                        <p>
                          If offered this position how would you want to get
                          paid?
                          <span>*</span>
                        </p>
                        <select
                          id=''
                          name='remote'
                          value={payment}
                          onChange={(e) => setPayment(e.target.value)}
                        >
                          <option value=''>-select-</option>
                          <option value='Direct deposit'>Direct deposit</option>
                          <option value='Cheque'>Cheque</option>
                        </select>
                      </div>
                    </div>
                    {loading ? (
                      <p>Submitting...........</p>
                    ) : (
                      <>
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setBoardCount(7)
                          }}
                        >
                          Previous
                        </button>
                        <button>Submit</button>
                      </>
                    )}
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
